.search-input {
  background-color: white;
  max-width: 530px;
  border-radius: 12px;
}

.search-input fieldset {
  border: 5px solid #f6b119;
  border-radius: 12px;
}

.login-input {
  background-color: #f9f9f9;
  border-radius: 10px !important;
}

.login-input fieldset {
  border: 0px;
}

.grid-input {
  margin-top: 24px;
}

.filter-search {
  height: 48px !important;
}

.filter-search fieldset {
  height: 48px !important;
}

.filter-search input {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}

.location-search {
  height: 42px !important;
  
}

.location-search fieldset {
  height: 42px !important;
}

.location-search input {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}

.price-filter {
  width: 120px !important;
  height: 42px !important;
  border: solid 0.5px #979797 !important;
}

.price-filter fieldset{
  height: 42px !important;
  border: 0;
}

.price-filter input {
  padding-top: 10px;
}

/* .textfield fieldset {
  border-radius: 30px !important;
  border-color: #969696;
  height: 50px;
  height: 50px !important; 
}
.search_field fieldset {
  height: 40px !important;
  font-size: var(--small-font);
}
.textarea fieldset {
  height: 140px;
}
.field-label{
  position: absolute;
  top: -12px;
  font-size: 13px;
  margin: 0 20px;
  background: #fff;
  z-index: 99;
  color: var(--secondary-color);
  padding: 0 10px;
}
.password-icon{
  color: var(--primary-color) !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  width: 22px !important;
  height: 22px !important;
}
.textfield.search-input{
  background: #eeeeef !important;
  border-radius: var(--lg-radius) !important;
}
.textfield.search-input fieldset{
  border-radius: var(--lg-radius) !important;
}
.textfield.search-bg{
  background: #eeeeef !important;
  border-radius: 30px;
}

.notes fieldset{
  height: 100px;
  border-radius: 15px !important;
} */