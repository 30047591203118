/* .search-select input {
  font-size: 20px;
  font-weight: 500;
  color: #272726;
}

.search-select fieldset {
  border: 0ch;
}

.search-select svg {
  color: #f6b119;
} */

.sort-select {
  height: 27px !important;
}

.sort-select fieldset {
  border: 0ch;
}

.sort-select div {
  color: #f6b119;
  font-size: 16px;
}

.sort-select svg {
  color: #f6b119;
}

/* .select-input fieldset {
  border-radius: 30px !important;
  border-color: #969696 !important;
  height: 50px !important;
   height: 50px !important; 
}

.select-multi fieldset {
  border-radius: 30px !important;
  border-color: #969696 !important;
}

.MuiAutocomplete-inputRoot {
  height: auto !important;
}

.select-error fieldset {
  border-radius: 30px !important;
  border-color: red !important;
  height: 50px !important;
   height: 50px !important; 
}

.select-label {
  position: absolute;
  top: -12px;
  font-size: 13px;
  margin: 0 20px;
  background: #fff;
  z-index: 99;
  color: var(--secondary-color);
  padding: 0 10px;
}

.select-label.grey {
  background: #f5f5f5 !important;
}

.select-input div,
.select-error div {
  text-align: start !important;
}

.select-input svg,
.select-error svg {
  color: var(--primary-color);
}

.select-input .MuiSelect-icon,
.select-error .MuiSelect-icon {
  position: relative !important;
  right: unset !important;
  top: 0 !important;
  margin: 0 15px !important;
} */