.card_shadow:hover {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
}

.card_shadow {
  background: #ffffff;
  border-radius: 12px;
}
.option_hidden {
  display: none !important;
}

.option_hover img {
  cursor: pointer;
}

.option_hover div {
  cursor: pointer;
}
.custom_input div::before {
  border-bottom: 0 !important;
}
.custom_input {
  display: flex !important;
}
.option_displayed {
  display: flex !important;
}
.simple-select {
  border-color: transparent !important;
}

.simple-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.degree-input input {
  padding-left: 5%;
}
