.code_selectAR {
  width: 100%;
  margin: 0 0px 0 15px !important;
}
.code_selectEN {
  width: 100%;
  /* margin: 0 15px 0 0 !important; */
}
.phone_field {
  width: 394px;
}

.select fieldset {
  width: 100% !important;
  border: none !important;
  border-radius: 4px;
}
.phone-select>svg{
  color: var(--primary-color) !important;
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  z-index: 9999;
}
.MuiSelect-select{
  background-color: rgb(245 247 251) !important;
}