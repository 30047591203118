.social-media-log {
  width: 200px;
  height: 60px;
  border: solid 1px rgba(39, 39, 38, 0.4) !important;
  border-radius: 10px !important;
  color: black !important;
  font-size: 22px;
  font-weight: 600;
  text-transform: none !important;
}

.orange-btn {
  width: 400px;
  height: 72px;
  background-color: #faa100 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 24px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.white-btn {
  width: 400px;
  height: 70px;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #faa100 !important;
  border: solid 1px #faa100 !important;
  border-radius: 10px !important;
  text-transform: none !important;
}

.account-type-btn {
  width: 400px;
  height: 130px;
  border-radius: 10px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f6b119 !important;
}

.filter-btn {
  width: 100%;
  height: 44px;
  color: #272726 !important;
  font-size: 20px !important;
  justify-content: space-between !important;
  text-transform: none !important;
}

.date-btn {
  width: 193px;
  height: 40px;
  background-color: #f6b119 !important;
  border-radius: 20px !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.book-btn {
  width: 100% !important;
  background: var(--primary-color) !important;
  height: 50px !important;
  border-radius: var(--md-radius) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 18px !important;
}