.ar .border-right-sidebar {
  border-left: solid 1px rgba(151, 151, 151, 0.25);
  border-right: none;
}
/* .App_Localization {
  font-family: ArbFont;
} */
.ar .pr-0 {
  padding-left: 0 !important;
  /*   padding-right: 15px !important; */
}

.ar .pl-0 {
  padding-right: 0 !important;
}

.ar .dLsUHG {
  padding-left: 40px;
  padding-right: 15px;
}
.ar .ktbNFJ {
  padding-left: 70px;
  padding-right: 0;
}

.ar .hTdUyB {
  padding-left: 40px;
  padding-right: 15px;
}
.ar .fileBtn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 30.5px;
  border-bottom-left-radius: 30.5px;
}
.ar .fileInput input {
  border-top-right-radius: 30.5px;
  border-bottom-right-radius: 30.5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ar .week-circle {
  margin-right: 0px;
  margin-left: 10px;
}
.ar .react-tel-input {
  direction: ltr;
}
.ar .custom-checkbox-parent label:after {
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 4px;
  right: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}
