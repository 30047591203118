.accept{
color:white;
background-color: #F2994A;
font-size: 12px !important;
cursor: pointer;
}

.cancel{
    background-color: #BDBDBD;
    color:white;
    font-size: 12px !important;
    cursor: pointer;
}

.calendar{
    color:white;
background-color: #F2994A;
font-size: 16px !important;
padding: 4px;
border-radius: 50%;
position: relative;
}

.date-input{
    padding: 5px !important;
}
.date-input:hover {
    background-color: #DAEAFF;
    border-radius: 8px;
}

.calender-input{
    position: absolute;
    left: 0;
    top:0;
}
