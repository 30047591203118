/* .toggle-btns-container div {
    padding: 1rem;
}
.toggle-btns-container.appointments div{
    padding: 1px;
}
.toggle-btns-container.appointments button{
    padding: 15%;
    background: #fff;
}

.toggle-container div {
    padding-inline-end: 1rem;
    padding-top: 1rem;
} */

.toggle-btn {
    background: #fff !important;
    color: #5570F1 !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 5px 1px #ddd;
    min-height: 40px !important;
    display: inline-block !important;
    line-height: 1.5 !important;
    text-align: start !important;
    align-items: start !important;
}

.toggle-btn.Mui-selected {
    color: #fff !important;
    background: #5570F1 !important;
    
}

.toggle-btn>.MuiTab-iconWrapper {
    margin-bottom: -8px !important;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
}

.switch-container {
    position: relative;
    width: max-content;
    height: 40px;
    
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
}

.switch-container-reasons {
    position: relative;
    width: max-content;
    height: 40px;
    cursor: pointer;
}


.switch-btn {
    width: 190px;
    display: inline-block;
    text-align: center;
    color: #2F80ED !important;
    line-height: 2.5;
    z-index: 2;
    position: relative;
    padding: 0 !important;
    text-transform: none !important;
    
}

.switch-btn-reasons {
    width: 130px;
    display: inline-block;
    text-align: center;
    /* color: #2F80ED !important; */
    line-height: 2.5;
    z-index: 2;
    position: relative;
    padding: 0 !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    
}
.switch-btn-reasons.Mui-selected {
    /* color: #fff !important; */
    /* background: #2F80ED; */
    border-radius: var(--xlg-radius);
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}



.switch-btn.Mui-selected {
    color: #fff !important;
    background: #2F80ED;
    border-radius: var(--xlg-radius);
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiTabs-indicator {
    display: none;
}

.switch-container-reasons .MuiTabs-indicator {
    display: block; /* Override the global style for your specific container */
}


.diagnosis-switch {
    width: max-content !important;
}

.settings-toggle-btn{
    height: 60px;
    width: 220px;
    text-align: center !important;
    align-items: center !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
}