html {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.App {
  text-align: center;
  box-sizing: border-box;
}

.page-items-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.nav-accordion::before {
  background-color: transparent !important;
}

.MuiInputLabel-shrink {
  display: none !important;
}

.custom_input div::before {
  border-bottom: 0 !important;
}

.custom_input {
  display: flex !important;
}

.custom_input_hover:hover .option_hover {
  display: flex !important;
}

.sub-section-img-size {
  width: 216px;
  height: 142px;
}

.popper-custom-style {
  position: absolute !important;
  z-index: 10;
  top: 25px !important;
}

.custom-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  color: #2F80ED;
  font-weight: 500;
}

.customMenu {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 32px !important;
}
.react-select__placeholder {
  color: red;
}