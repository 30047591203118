.custom_editable_input div::before{
border-bottom: 0 !important;
}
.custom_editable_input{
display: flex !important
}

.custom_editable_input:hover {
background-color: #DAEAFF;
}


    
.custom_editable_input  {
padding: 8px;
}

.custom_editable_container{
    background-color: transparent;
    border: 0;
    width: 100%;
}

.custom_editable_container:hover .controles{
    background-color: transparent;
}

