.MuiRadio-root.Mui-checked{
    color: var(--secondary-color) !important;
}
.MuiRadio-root.Mui-focused{
    color: var(--secondary-color) !important;
}
.radio-full-width>.MuiFormControl-root{
    width: 100% !important;
}
.accordion-style{
    background: #fbfbfb;
    padding: 0px 20px !important;
}
.container{
    position: relative;
}
.container span:nth-of-type(2){
    margin: 0px 15px;
}
.radioAvatar-ar{
    position: absolute;
    top: 13px;
    right: 28px;
    width: 16px;
}
.radioAvatar{
    position: absolute;
    top: 13px;
    left: 28px;
    width: 16px;
}